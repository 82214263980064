/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/color.scss';
.sectionWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.container {
  max-width: 1120px;
  width: 100%;
}

.buttonGroup {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
}

.title {
  color: $white-text;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
}

.descriptionItem {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: $white-text;
  align-items: center;
  margin-bottom: 0 !important;
}

.descriptionList {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-shrink: 0;
  align-items: center;
}

@media screen and (max-width: 991px) and (min-width: 0) {
  .title {
    font-size: 40px;
    line-height: 48px;
  }

  .descriptionItem {
    font-size: 16px;
    line-height: 24px;
  }

  .buttonGroup {
    margin-top: 48px;
    gap: 12px;
  }
}
