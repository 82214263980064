/* stylelint-disable selector-class-pattern */
.cardListModuleWrap {
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardListModule {
  max-width: 1080px;
  width: 100%;
}

.title {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  color: var(--primaryText);
}

.subTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 16px;
  text-align: center;
  color: var(--secondaryText);
}

.cardList {
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.cardItem {
  width: 100%;
  overflow: hidden;
  max-width: 728px;
  margin: auto;
}

.cardDesc {
  padding: 32px 32px 24px;
}

.cardItemTitle {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  color: var(--primaryText);
}

.cardItemSubTitle {
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  color: var(--secondaryText);
}

.cardImgContainer {
  display: flex;
  height: 242px;
  overflow: hidden;
}

.cardImg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100% !important;
    height: 100% !important;
  }
}

.cardItemCol {
  height: 429px;
  padding: 8px;
  text-align: center;
}

.cardItemBox {
  width: 100%;
  height: calc(100% - 10px);
  padding: 40px 20px 0;
  overflow: hidden;
  border-radius: 6px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
}

.cardTitleColBox {
  width: 100%;
  min-height: 120px;
  text-align: left;
}

.cardTitleCol {
  font-size: 18px;
  font-weight: 700;
  font-family: WorkSans-Bold, PingFangSC-Semibold, PingFang SC, sans-serif;
  line-height: 24px;
  color: #1a1a1a;
}

.cardDescriptionCol {
  font-size: 14px;
  line-height: 20px;
  color: #808080;
  margin-top: 8px;
}

.cardImgCol {
  gap: 8px;
}

.frame3Img {
  vertical-align: top;
  margin-right: 10px;
  margin-left: -30px;
}

.frame4Img {
  margin-left: -60px;
}

@media screen and (max-width: 991px) {
  .cardTitleColBox {
    margin-bottom: 10px;
  }
  .frame3Img {
    margin-left: 0;
  }
  .frame4Img {
    margin-left: 0;
  }
  .cardItemCol {
    margin-bottom: 8px;
  }
  .cardList {
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
    margin-top: 40px;
  }
  .cardItemSubTitle {
    font-size: 14px;
    line-height: 22px;
  }
  .cardImgContainer {
    height: 212px;
  }
  .title {
    font-size: 30px;
    line-height: 38px;
  }
  .subTitle {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (min-width: 580px) and (max-width: 991px) {
  .cardImg {
    padding: 0;
    img {
      width: 438px;
      height: 200px !important;
    }
  }
}

@media screen and (max-width: 579px) {
  .cardImg {
    padding: 0;
    img {
      width: 294px;
      height: 134px !important;
    }
  }
}
