/* stylelint-disable selector-class-pattern */
.rowDescription {
  display: flex;
  align-items: flex-start;
}

.secondaryList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--primaryText);
}

.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  color: var(--primaryText);
}

.desc {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: var(--secondaryText);
}
