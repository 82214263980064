/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/color.scss';
@import '/src/assets/theme/constants.scss';

.featureCardModuleWrapper {
  width: 100%;
  background: $page-bg;

  .cardWrapperBox {
    width: 100%;
    min-height: 500px;
  }

  .cardRow {
    width: 100%;
    min-height: 500px;
    background-color: #1a1a1a;
    border-radius: 8px;
    padding: 40px 40px 0;
    position: relative;
    left: 12px;
  }

  .cardCol {
    padding: 10px 40px;
    overflow: hidden;
  }

  .featureTileBox {
    height: 200px;
  }

  .step {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #8c8c8c;
    margin-bottom: 16px;
  }

  .featureTile {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    color: #e8e8e8;
    margin-bottom: 16px;
  }

  .featureDes {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px;
    color: #8c8c8c;
  }

  .arrowBox {
    display: block;
    font-size: 16px;
    line-height: 22px;
    color: #1370dd;
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .featureCardModuleContentWrapper {
    width: 100%;
    gap: 40px;
    max-width: 1080px;
  }

  .sectionTitleWrapper {
    gap: 16px;
  }

  .sectionTitle {
    font-weight: 700;
    font-size: 40px;
    font-family: WorkSans-Bold, PingFangSC-Semibold, PingFang SC, sans-serif;
    line-height: 48px;
    text-align: center;
    color: $primary-text;
  }

  .sectionSubTitle {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $secondary-text;
  }

  .sectionCardWrapper {
    display: flex;
    gap: 24px;
    justify-content: center;
    min-height: 400px;
  }

  @media screen and (min-width: #{$divide-991 + 1}) {
    .sectionCardWrapper {
      .card {
        flex: 1;
      }
    }
  }

  @media screen and (max-width: $divide-991) {
    .featureCardModuleContentWrapper {
      gap: 20px;
    }

    .sectionTitle {
      font-size: 30px;
      line-height: 40px;
      max-width: $content-width-tablet;
      min-width: $content-width-mobile;
    }
    .sectionCardWrapper {
      max-width: $content-width-tablet;
      min-width: $content-width-mobile;
      flex-wrap: wrap;
    }
  }
}
