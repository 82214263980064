/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/color.scss';

.commonButton {
  height: 50px;
  padding: 4px 15px;
  border: 1px solid rgb(255 255 255 / 60%);
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  color: $white-text;
  background: transparent;

  &:hover {
    border: 1px solid rgb(255 255 255 / 60%);
    color: $white-text;
    opacity: 0.8;
    background: transparent;
  }
  &:focus {
    border: 1px solid rgb(255 255 255 / 60%);
    color: $white-text;
    opacity: 1;
    background: transparent;
  }
}
