/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/color.scss';
@import '/src/assets/theme/constants.scss';

.featureDescModuleWrap {
  display: flex;
  justify-content: center;
  .container {
    max-width: $divide-1080;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    color: $white-text;
  }

  .image {
    max-width: 1080px;
    max-height: 500px;
    margin-top: 80px;
    position: relative;
    width: 100%;
    height: auto;
  }

  .content {
    margin-top: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px 120px;
    width: 100%;
    line-height: 26px;
    font-size: 18px;
    color: $white-text;
  }

  @media screen and (max-width: $divide-991) {
    .title {
      font-size: 30px;
      line-height: 38px;
    }

    .content {
      margin-top: 40px;
      grid-template-columns: 1fr;
    }
  }

  @media screen and (max-width: $divide-991) and (min-width: $divide-768) {
    .container {
      width: 728px;
    }
  }

  @media screen and (max-width: $divide-767) and (min-width: $divide-580) {
    .container {
      width: 540px;
    }
  }

  @media screen and (max-width: $divide-579) {
    .container {
      padding: 0 20px;
    }
  }
}
